import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PrivacyPage from "../components/privacy-policy/privacy-policy"

const Privacy = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <PrivacyPage />
  </Layout>
)

export default Privacy
